const Share = () => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3261 8.50715C16.5296 8.33277 16.6313 8.24559 16.6686 8.14184C16.7013 8.05078 16.7013 7.95117 16.6686 7.86011C16.6313 7.75636 16.5296 7.66918 16.3261 7.4948L9.26719 1.44429C8.917 1.14412 8.74191 0.99404 8.59367 0.990363C8.46483 0.987167 8.34177 1.04377 8.26035 1.14367C8.16667 1.25861 8.16667 1.48923 8.16667 1.95045V5.52984C6.38777 5.84105 4.75966 6.74244 3.54976 8.09586C2.23069 9.5714 1.50103 11.4809 1.5 13.4601V13.9701C2.37445 12.9167 3.46626 12.0647 4.70063 11.4726C5.78891 10.9505 6.96535 10.6412 8.16667 10.5597V14.0515C8.16667 14.5127 8.16667 14.7433 8.26035 14.8583C8.34177 14.9582 8.46483 15.0148 8.59367 15.0116C8.74191 15.0079 8.917 14.8578 9.26719 14.5577L16.3261 8.50715Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Share;
