const Translate = () => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.7608 13.1667H16.7391M10.7608 13.1667L9.16663 16.5M10.7608 13.1667L13.1485 8.17419C13.3409 7.77189 13.4371 7.57075 13.5688 7.50718C13.6832 7.4519 13.8167 7.4519 13.9311 7.50718C14.0628 7.57075 14.159 7.77189 14.3514 8.17419L16.7391 13.1667M16.7391 13.1667L18.3333 16.5M1.66663 3.16667H6.66663M6.66663 3.16667H9.58329M6.66663 3.16667V1.5M9.58329 3.16667H11.6666M9.58329 3.16667C9.16984 5.63107 8.21045 7.86349 6.80458 9.73702M8.33329 10.6667C7.82285 10.4373 7.30217 10.1184 6.80458 9.73702M6.80458 9.73702C5.67748 8.87314 4.66893 7.68886 4.16663 6.5M6.80458 9.73702C5.46734 11.5191 3.72615 12.9765 1.66663 14"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Translate;
